import { Box, AppBar, Toolbar } from '@mui/material';
import EntityListTopBarContent from './EntityListTopBarContent';
import Navigation from './Navigation';

const GenericTopBar = () => {
	return (
		<Box sx={{ m: 0, display: 'flex', flexGrow: 1 }}>
			<AppBar sx={{ maxHeight: '10vh', boxShadow: 'none' }} position='static' color='inherit'>
				<Toolbar
					sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
				>
					<Navigation />
					<EntityListTopBarContent />
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default GenericTopBar;
