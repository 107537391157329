import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { getCourses } from '../data/repository/CourseRepository';
import { CourseModel } from '../data/model/CourseModel';
import { useNavigate } from 'react-router-dom';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
function QuickSearchToolbar() {
	return (
		<Box
			sx={{
				p: 0.5,
				pb: 0
			}}
		>
			<GridToolbarQuickFilter />
		</Box>
	);
}

const columns: GridColDef[] = [
	{ field: 'abbreviation', headerName: 'Abbreviation', width: 150 },
	{ field: 'name', headerName: 'Name', width: 300 },
	{ field: 'englishName', headerName: 'English name', width: 300 },
	{
		field: 'actions',
		headerName: 'Actions',
		sortable: false,
		width: 300,
		renderCell: () => {
			return <Box></Box>;
		}
	}
];

function ListCourses() {
	const [rows, setData] = useState<CourseModel[]>([]);
	const navigate = useNavigate();
	useEffect(() => {
		getCourses()
			.then((data) => {
				setData(data);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	}, []);
	return (
		<Box sx={{ m: 0, display: 'flex', flexGrow: 1, height: '100%' }}>
			<DataGrid
				rows={rows}
				columns={columns}
				checkboxSelection
				disableColumnSelector
				disableRowSelectionOnClick
				autoPageSize
				onRowClick={(params) => navigate(`/courses/${params.id}`)}
				slots={{ toolbar: QuickSearchToolbar }}
			/>
		</Box>
	);
}

export default ListCourses;
