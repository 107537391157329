import { CourseModel } from '../model/CourseModel';
import { apiClient, withApi } from '../Common';

export { getCourses, getCourse, postCourse, putCourse, deleteCourse };

async function getCourses(): Promise<CourseModel[]> {
	return withApi(() => {
		return apiClient.get('courses');
	});
}

async function getCourse(id: string): Promise<CourseModel> {
	return withApi(() => {
		return apiClient.get(`courses/${id}`);
	});
}

async function postCourse(course: CourseModel): Promise<CourseModel> {
	return withApi(() => {
		return apiClient.post('courses', course);
	});
}

async function putCourse(id: string, course: CourseModel): Promise<CourseModel> {
	return withApi(() => {
		return apiClient.put(`courses/${id}`, course);
	});
}

async function deleteCourse(id: string): Promise<CourseModel> {
	return withApi(() => {
		return apiClient.delete(`courses/${id}`);
	});
}
