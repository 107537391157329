import { TextField, Typography } from '@mui/material';
import EntityDetailFieldContainer from './EntityDetailFieldContainer';

function EntityDetailTextField(props: {
	title: string;
	readonly: boolean;
	value: string;
	name: string;
	onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}) {
	return (
		<EntityDetailFieldContainer longText={props.value.length > 200}>
			<Typography sx={{ mr: 1, width: '300px' }} variant='h6'>
				{props.title}
			</Typography>
			<TextField
				id={props.name}
				sx={{ display: 'flex', width: '100%' }}
				disabled={!props.readonly}
				name={props.name}
				size='small'
				multiline
				value={props.value}
				onChange={props.onChange}
			></TextField>
		</EntityDetailFieldContainer>
	);
}

export default EntityDetailTextField;
