import React from 'react';
import { ListItemIcon, ListItemText, ListItem, ListItemButton } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import SvgIcon from '@mui/icons-material/MoveToInbox';

function MyListItemButton({
	open,
	text,
	myIcon
}: {
	open: boolean;
	text: string;
	myIcon: SvgIconComponent;
}) {
	return (
		<ListItem key={text} disablePadding sx={{ display: 'block' }}>
			<ListItemButton
				sx={{
					minHeight: 48,
					justifyContent: open ? 'initial' : 'center',
					px: 2.5
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: open ? 3 : 'auto',
						justifyContent: 'center'
					}}
				>
					<SvgIcon component={myIcon} inheritViewBox />
				</ListItemIcon>
				<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
			</ListItemButton>
		</ListItem>
	);
}

export default MyListItemButton;
