import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography } from '@mui/material';
import EntityDetailFieldContainer from './EntityDetailFieldContainer';

export default function EntityDetailSelectBox(props: {
	title: string;
	readonly: boolean;
	name: string;
	values: string[];
	value: string;
	onChange: (value: SelectChangeEvent<string>) => void;
}) {
	return (
		<EntityDetailFieldContainer>
			<Typography sx={{ mr: 1, width: '300px' }} variant='h6'>
				{props.title}
			</Typography>
			<Select
				id={props.name}
				value={props.value}
				disabled={!props.readonly}
				size='small'
				name={props.name}
				onChange={props.onChange}
			>
				{props.values.map((value, index) => (
					<MenuItem key={index} value={value}>
						{value}
					</MenuItem>
				))}
			</Select>
		</EntityDetailFieldContainer>
	);
}
