import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import SchoolIcon from '@mui/icons-material/School';
import LogoutIcon from '@mui/icons-material/Logout';
import MyListItemButton from './MyListItemButton';
import { Link } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import * as LoginRepository from '../../data/repository/LoginRepository';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme)
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme)
		})
	})
);

function Navbar() {
	const [open, setOpen] = React.useState(false);

	const handleDrawerSwitch = () => {
		setOpen(!open);
	};

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Drawer
					PaperProps={{
						style: {
							position: 'relative' //Important for the component to be under top bar
						}
					}}
					variant='permanent'
					open={open}
				>
					<Box sx={{ display: 'flex-row', maxHeight: '10vh' }}>
						<Box>
							<DrawerHeader>
								<Box>
									<IconButton onClick={handleDrawerSwitch}>
										{!open ? <MenuIcon /> : <MenuOpenIcon />}
									</IconButton>
								</Box>
							</DrawerHeader>
							<Divider />
						</Box>
					</Box>
					<Box
						display='flex'
						flexDirection='column'
						justifyContent='space-between'
						sx={{ flexGrow: 99 }}
					>
						<Box>
							<List>
								<Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
									<MyListItemButton open={open} text='Home' myIcon={HomeIcon} />
								</Link>
								<Link to='/cv' style={{ textDecoration: 'none', color: 'black' }}>
									<MyListItemButton
										open={open}
										text='CV'
										myIcon={ContactPageIcon}
									/>
								</Link>
								<Link
									to='/courses'
									style={{ textDecoration: 'none', color: 'black' }}
								>
									<MyListItemButton
										open={open}
										text='Courses'
										myIcon={WatchLaterIcon}
									/>
								</Link>
								<Link
									to='/specialization'
									style={{ textDecoration: 'none', color: 'black' }}
								>
									<MyListItemButton
										open={open}
										text='Specialization'
										myIcon={FolderSpecialIcon}
									/>
								</Link>
								<Link
									to='/programmes'
									style={{ textDecoration: 'none', color: 'black' }}
								>
									<MyListItemButton
										open={open}
										text='Programmes'
										myIcon={SchoolIcon}
									/>
								</Link>
							</List>
						</Box>
						<Box>
							<List>
								<Link
									to={'/login'}
									style={{ textDecoration: 'none', color: 'black' }}
									onClick={() => LoginRepository.logout()}
								>
									<MyListItemButton
										open={open}
										text='Log Out'
										myIcon={LogoutIcon}
									/>
								</Link>
							</List>
						</Box>
					</Box>
				</Drawer>
			</Box>
		</>
	);
}

export default Navbar;
