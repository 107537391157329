import { Card, Typography, Button, Box, CircularProgress } from '@mui/material';
import { Link, useLocation, useNavigate, useSearchParams, type Location } from 'react-router-dom';
import { FC, ReactElement, useEffect, useReducer } from 'react';
import * as LoginRepository from '../data/repository/LoginRepository';
import { SSO_LOGIN_URL } from '../data/Common';

export default LoginCard;

interface LoginState {
	error: string | null;
	loading: boolean;
	isLoggedIn: boolean;
	authCode: string | null;
}

const initialState: LoginState = {
	error: null,
	loading: false,
	isLoggedIn: false,
	authCode: null
};

type OnError = { type: 'onError'; message: string };
type OnStartLoading = { type: 'onStartLoading' };
type OnAuthCode = { type: 'onAuthCode'; code: string | null };
type OnLoggedIn = { type: 'onLoggedIn' };
type OnLoggedOut = { type: 'onLoggedOut' };

type LoginAction = OnError | OnStartLoading | OnAuthCode | OnLoggedIn | OnLoggedOut;

function reducer(state: LoginState, action: LoginAction): LoginState {
	switch (action.type) {
		case 'onError':
			return { ...state, error: action.message, loading: false };
		case 'onStartLoading':
			return { ...state, loading: true, error: null };
		case 'onAuthCode':
			return { ...state, authCode: action.code };
		case 'onLoggedIn':
			return { ...state, loading: false, authCode: null, isLoggedIn: true };
		case 'onLoggedOut':
			return { ...state, isLoggedIn: false };
		default:
			return state;
	}
}

function LoginCard() {
	const { state: locationState }: Location<{ from: string | null }> = useLocation();
	const [state, dispatch] = useReducer(reducer, initialState);
	const [searchParameters, setSearchParameters] = useSearchParams();
	const navigate = useNavigate();

	// Update logged in state of user
	useEffect(() => {
		const isLoggedIn = LoginRepository.isLoggedIn();
		if (isLoggedIn) {
			dispatch({ type: 'onLoggedIn' });
		} else {
			dispatch({ type: 'onLoggedOut' });
		}
	}, []);

	// Redirect user to main page if they are logged in
	useEffect(() => {
		if (state.isLoggedIn) {
			const from = locationState?.from ?? '/'; //TODO from is currently always null, probably won't be fixable here.
			navigate(from, { replace: true });
		}
	}, [state.isLoggedIn]);

	// Update authCode state
	useEffect(() => {
		const paramsCode = searchParameters.get('code');
		if (paramsCode != null) {
			dispatch({ type: 'onAuthCode', code: paramsCode });
			searchParameters.delete('code');
			setSearchParameters(searchParameters);
		}
	}, [searchParameters]);

	// Start login flow if we get a code
	useEffect(() => {
		if (state.authCode == null) {
			return;
		}

		dispatch({ type: 'onStartLoading' });
		LoginRepository.login(state.authCode)
			.then(() => {
				dispatch({ type: 'onLoggedIn' });
			})
			.catch(() => {
				dispatch({ type: 'onError', message: 'Chyba přihlášení...' });
			});
	}, [state.authCode]);

	return (
		<Box
			sx={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				height: '98vh'
			}}
		>
			<Card
				variant='outlined'
				sx={{
					bgcolor: '#fff',
					boxShadow: 5,
					borderRadius: 2,
					padding: 3
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<img src={'/Apa.png'} height={200} />
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							padding: 5
						}}
					>
						<Typography variant='h3' gutterBottom={false} align={'center'}>
							Akrmat
						</Typography>

						<Typography
							variant='body1'
							gutterBottom={false}
							align={'center'}
							style={{ color: 'red' }}
						>
							{state.error}
						</Typography>
						<LoginButton loading={state.loading} />
					</Box>
				</Box>
			</Card>
		</Box>
	);
}

const LoginButton: FC<{ loading: boolean }> = ({ loading }): ReactElement => {
	if (loading) {
		return (
			<CircularProgress
				style={{
					marginTop: 15
				}}
			/>
		);
	}

	return (
		<Link
			to={SSO_LOGIN_URL}
			style={{
				padding: 5,
				paddingTop: 15
			}}
		>
			<Button variant='contained'>Login using SSO</Button>
		</Link>
	);
};
