import {
	Alert,
	Box,
	Button,
	Grid,
	SelectChangeEvent,
	Snackbar,
	TextField,
	Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { CourseModel, Lecture, Literature } from '../data/model/CourseModel';
import { getCourse, putCourse } from '../data/repository/CourseRepository';
import ArrayField from '../components/EntityDetail/ArrayFields';
import EntityDetailSelectBox from '../components/EntityDetail/EntityDetailSelectBox';
import EntityDetailTextField from '../components/EntityDetail/EntityDetailTextField';
import { useParams } from 'react-router-dom';

function CourseDetail() {
	const [editMode, setEditMode] = useState<boolean>(false);
	const [courseData, setCourseData] = useState<CourseModel>();
	const [snackbarOpenSucces, setSnackbarOpenSucces] = useState(false);
	const [snackbarOpenFail, setSnackbarOpenFail] = useState(false);

	const params = useParams();
	const id = params?.id ?? '';

	useEffect(() => {
		getCourse(id).then((data) => {
			setCourseData(data);
		});
	}, [id]);

	const saveData = useCallback(() => {
		courseData &&
			putCourse(id, courseData)
				.then(() => {
					setSnackbarOpenSucces(true);
					setEditMode(false);
				})
				.catch((e) => {
					console.log(e);
					setSnackbarOpenFail(true);
				});
	}, [courseData]);

	const handleFieldChange = (
		e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
	) => {
		const { name, value } = e.target;

		setCourseData((prevData) => ({
			...prevData,
			[name]: value
		}));
	};

	const renderLectureField = useCallback(
		(item: Lecture, index: number, editMode: boolean, onChange: (e: Lecture) => void) => (
			<LectureField item={item} index={index} editMode={editMode} onChange={onChange} />
		),
		[]
	);
	const renderLiteratureField = useCallback(
		(item: Literature, index: number, editMode: boolean, onChange: (e: Literature) => void) => (
			<LiteratureField item={item} index={index} editMode={editMode} onChange={onChange} />
		),
		[]
	);

	return (
		<>
			<Snackbar
				open={snackbarOpenSucces}
				autoHideDuration={2000}
				message='Data saved'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
					if (reason === 'clickaway') return;
					setSnackbarOpenSucces(false);
				}}
			>
				<Alert severity='success'>Data saved</Alert>
			</Snackbar>
			<Snackbar
				open={snackbarOpenFail}
				autoHideDuration={2000}
				message='Error when saving data. Check console for more info'
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
					if (reason === 'clickaway') return;
					setSnackbarOpenFail(false);
				}}
			>
				<Alert severity='error'>Error when saving data. Check console for more info</Alert>
			</Snackbar>
			{courseData && (
				<Box sx={{ p: 1, m: 1 }}>
					<Button onClick={() => setEditMode((prev) => !prev)}>Editovat</Button>
					<Button onClick={saveData}>Uložit</Button>
					<Button onClick={() => console.log(courseData)}>[DEBUG]Print data</Button>
					<Grid container spacing={1}>
						<EntityDetailTextField
							title='Zkratka předmětu'
							name='abbreviation'
							readonly={editMode}
							value={courseData?.abbreviation ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Název předmětu'
							name='name'
							readonly={editMode}
							value={courseData?.name ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Název předmětu v AJ'
							name='englishName'
							readonly={editMode}
							value={courseData?.englishName ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Kredity'
							name='creditAmount'
							readonly={editMode}
							value={courseData?.creditAmount?.toString() ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Konzultace'
							name='consultations'
							readonly={editMode}
							value={courseData?.consultations ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailSelectBox
							title='Ukončení'
							name='completion'
							values={[
								'KZ - klasifikovaný zápočet',
								'Z - zápočet',
								'Z,ZK - zápočet a zkouška',
								'O - obhajoba',
								'NIC - nic',
								'ZK - zkouška',
								'nedefinovaný'
							]}
							readonly={editMode}
							value={courseData?.completion ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Garant' //TODO
							name='abbreviation'
							readonly={editMode}
							value={courseData?.abbreviation ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Zapojení garanta do výuky předmětu'
							name='guarantorInvolvement'
							readonly={editMode}
							value={courseData?.guarantorInvolvement ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Informace o kontaktu s vyuřujicím'
							name='teacherContact'
							readonly={editMode}
							value={courseData?.teacherContact ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Forma výuky'
							name='formOfTeaching'
							readonly={editMode}
							value={courseData?.formOfTeaching ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Prerekvizity'
							name='correlationOverlap'
							readonly={editMode}
							value={courseData?.correlationOverlap ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Idk, type'
							name='type'
							readonly={editMode}
							value={courseData?.type ?? ''}
							onChange={handleFieldChange}
						/>
						<EntityDetailTextField
							title='Cíle předmětu'
							name='goals'
							readonly={editMode}
							value={courseData?.goals ?? ''}
							onChange={handleFieldChange}
						/>
						<ArrayField
							title='Přednášky'
							data={courseData.lecturesCzech ?? []}
							child={renderLectureField}
							editMode={editMode}
							onChange={(e: Lecture[]) => {
								setCourseData((prevData) => ({
									...prevData,
									['lecturesCzech']: e
								}));
							}}
						></ArrayField>
						<ArrayField
							title='Literatura'
							data={courseData.literatureCzech ?? []}
							child={renderLiteratureField}
							editMode={editMode}
							onChange={(e: Literature[]) => {
								setCourseData((prevData) => ({
									...prevData,
									['literatureCzech']: e
								}));
							}}
						/>
					</Grid>
				</Box>
			)}
		</>
	);
}

function LectureField({
	item,
	index,
	editMode,
	onChange
}: {
	item: Lecture;
	index: number;
	editMode: boolean;
	onChange: (e: Lecture) => void;
}) {
	return (
		<Grid container spacing={1}>
			<Grid display='flex' item xs={12} md={12} lg={12}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%'
					}}
				>
					<Typography variant='h6' sx={{ width: '300px' }}>
						{index + 1}
					</Typography>
					<TextField
						sx={{ width: '100%' }}
						size='small'
						multiline
						value={item.description ?? ''} //This is here to prevent error when changing from undefined to defined value. It doesn't break the app, but logs an error in dev mode
						disabled={!editMode}
						onChange={(e) => onChange({ ...item, ['description']: e.target.value })}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}

function LiteratureField({
	item,
	editMode,
	onChange
}: {
	item: Literature;
	index: number;
	editMode: boolean;
	onChange: (e: Literature) => void;
}) {
	return (
		<Grid container spacing={1}>
			<Grid display='flex' item xs={12} md={12} lg={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%'
					}}
				>
					<Typography variant='h6' sx={{ width: '300px' }}>
						Název
					</Typography>
					<TextField
						sx={{ width: '100%' }}
						size='small'
						value={item.name ?? ''} //This is here to prevent error when changing from undefined to defined value. It doesn't break the app, but logs an error in dev mode
						disabled={!editMode}
						onChange={(e) => onChange({ ...item, ['name']: e.target.value })}
					/>
				</Box>
			</Grid>
			<Grid display='flex' item xs={12} md={12} lg={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%'
					}}
				>
					<Typography variant='h6' sx={{ width: '300px' }}>
						Rok vydání
					</Typography>
					<TextField
						sx={{ width: '100%' }}
						size='small'
						multiline
						value={item.year ?? ''}
						disabled={!editMode}
						onChange={(e) => onChange({ ...item, ['year']: e.target.value })}
					/>
				</Box>
			</Grid>
			<Grid display='flex' item xs={12} md={12} lg={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%'
					}}
				>
					<Typography variant='h6' sx={{ width: '300px' }}>
						Autoři
					</Typography>
					<TextField
						sx={{ width: '100%' }}
						size='small'
						multiline
						value={item.authors ?? ''}
						disabled={!editMode}
						onChange={(e) => onChange({ ...item, ['authors']: e.target.value })}
					/>
				</Box>
			</Grid>
			<Grid display='flex' item xs={12} md={12} lg={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%'
					}}
				>
					<Typography variant='h6' sx={{ width: '300px' }}>
						ISBN
					</Typography>
					<TextField
						sx={{ width: '100%' }}
						size='small'
						multiline
						value={item.ISBN ?? ''}
						disabled={!editMode}
						onChange={(e) => onChange({ ...item, ['ISBN']: e.target.value })}
					/>
				</Box>
			</Grid>
			<Grid display='flex' item xs={12} md={12} lg={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%'
					}}
				>
					<Typography variant='h6' sx={{ width: '300px' }}>
						Vydavatel
					</Typography>
					<TextField
						sx={{ width: '100%' }}
						size='small'
						multiline
						value={item.publisher ?? ''}
						disabled={!editMode}
						onChange={(e) => onChange({ ...item, ['publisher']: e.target.value })}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}

export default CourseDetail;
