import { LoginResponse } from '../model/LoginModel';
import { AUTH_DISABLED, apiClient, withApi } from '../Common';

export { login, logout, isLoggedIn };

const KEY_JWT_EXPIRATION = 'jwtExpiration';

async function login(code: string): Promise<LoginResponse> {
	const response = await withApi(() => {
		return apiClient.post<LoginResponse>(`/login?authorization_code=${code}`);
	});
	const expiresAtMs = Date.now() + parseInt(response.expiresInSeconds) * 1000;
	localStorage.setItem(KEY_JWT_EXPIRATION, expiresAtMs.toString());
	return response;
}

function logout() {
	localStorage.removeItem(KEY_JWT_EXPIRATION);
}

function isLoggedIn(): boolean {
	if (AUTH_DISABLED) {
		return true;
	}

	const expiresAtMs = localStorage.getItem(KEY_JWT_EXPIRATION);
	if (expiresAtMs == null) {
		return false;
	}

	const isExpired = Date.now() >= parseInt(expiresAtMs);
	if (isExpired) {
		logout();
	}

	return !isExpired;
}
