import { Stack } from '@mui/material';
import SearchBar from './SearchBar';

const EntityListTopBarContent = () => {
	return (
		<Stack direction={'row'} spacing={5}>
			<SearchBar />
		</Stack>
	);
};

export default EntityListTopBarContent;
