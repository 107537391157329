import { Box, Card, Grid } from '@mui/material';
import { ReactNode } from 'react';

function EntityDetailFieldContainer(props: { children: ReactNode; longText?: boolean }) {
	return (
		<Grid display='flex' item xs={12} md={12} lg={props.longText ? 12 : 6}>
			<Card sx={{ width: '100%' }}>
				<Box
					sx={{
						m: 1,
						p: 1,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					{props.children}
				</Box>
			</Card>
		</Grid>
	);
}

export default EntityDetailFieldContainer;
