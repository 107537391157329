import LoginCard from './layouts/LoginCard';
import DefaultAppLayout from './layouts/DefaultAppLayout';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import ListCourses from './layouts/ListCourses';
import CourseDetail from './layouts/CourseDetail';
import { Box } from '@mui/material';

function App() {
	return (
		<>
			<Routes>
				<Route path={'/login'} element={<LoginCard />} />
				<Route element={<AuthenticatedRoute />}>
					<Route path={'/'} element={<DefaultAppLayout />}>
						<Route path='/courses' element={<ListCourses />}></Route>
						<Route path='/courses/:id' element={<CourseDetail />}></Route>
						<Route path='/cv' element={<Box>CVs</Box>}></Route>
						<Route path='/cv/:id' element={<Box>CV Detail</Box>}></Route>
						<Route path='/specialization' element={<Box>Specializations</Box>}></Route>
						<Route
							path='/specialization/:id'
							element={<Box>program Detail</Box>}
						></Route>
						<Route path='/programmes' element={<Box>Programmes</Box>}></Route>
						<Route
							path='/programmes/:id'
							element={<Box>Programmes Detail</Box>}
						></Route>
					</Route>
				</Route>
			</Routes>
		</>
	);
}

export default App;
