import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid,
	Typography,
	Button,
	Divider,
	IconButton
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useCallback } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export type ArrayFieldProps<T> = {
	title: string;
	data: T[];
	child: (item: T, index: number, editMode: boolean, onChange: (e: T) => void) => JSX.Element;
	editMode: boolean;
	onChange: (items: T[]) => void;
};

export default function ArrayField<T>(props: ArrayFieldProps<T>) {
	const addField = useCallback(() => {
		const res = [...props.data];
		res.push({} as T);
		props.onChange(res);
	}, [props.data]);

	const deleteField = useCallback(
		(index: number) => {
			const res = [...props.data];
			res.splice(index, 1);
			props.onChange(res);
		},
		[props.data]
	);
	return (
		<Grid display='flex' item xs={12} md={12} lg={12}>
			<Box display='flex' sx={{ alignItems: 'center', width: '100%' }}>
				<Accordion sx={{ width: '100%' }}>
					<AccordionSummary
						sx={{ display: 'flex', width: '100%' }}
						expandIcon={<ExpandMore />}
						aria-controls='panel1a-content'
						id='panel1a-header'
					>
						<Typography variant='h6'>{props.title}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Divider sx={{ mb: 2 }} />
						{props.data &&
							props.data.map((child, i, arr) => (
								<Box key={i}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between'
										}}
									>
										{props.child(child, i, props.editMode, (item) => {
											const res = [...arr];
											res[i] = item;
											props.onChange(res);
										})}
										<IconButton
											disabled={!props.editMode}
											color='error'
											onClick={() => deleteField(i)}
											sx={{ height: '35px', ml: 2 }}
										>
											<DeleteOutlineOutlinedIcon />
										</IconButton>
									</Box>
									<Divider sx={{ my: 2 }} />
								</Box>
							))}
						<Button variant='contained' disabled={!props.editMode} onClick={addField}>
							Přidat
						</Button>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Grid>
	);
}
