import { Navigate, useLocation, Outlet } from 'react-router-dom';
import * as LoginRepository from './data/repository/LoginRepository';

export { AuthenticatedRoute };

const AuthenticatedRoute = (): JSX.Element => {
	const isLoggedIn: boolean = LoginRepository.isLoggedIn();
	const location = useLocation();

	return isLoggedIn ? (
		<Outlet />
	) : (
		<Navigate
			replace={true}
			to='/login'
			state={{ from: `${location.pathname}${location.search}` }}
		/>
	);
};
