import { Box, Breadcrumbs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const Navigation = () => {
	const location = useLocation();
	const breadcrumbs = location.pathname.split('/').filter((v) => v.length > 0);
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
			<img height='40px' src='/logo_horizontal.svg' alt='' />
			<Breadcrumbs sx={{ flexGrow: 1, pl: 2 }}>
				<Link style={{ textDecoration: 'none', color: 'black' }} to='/'>
					Home
				</Link>
				{breadcrumbs.map((path, i, arr) => (
					<Link
						style={{ textDecoration: 'none', color: 'black' }}
						to={arr.slice(0, i + 1)?.join('/')}
					>
						{path}
					</Link>
				))}
			</Breadcrumbs>
		</Box>
	);
};

export default Navigation;
