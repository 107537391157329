import Navbar from '../components/Drawer/Navbar';
import { Box } from '@mui/material';
import GenericTopBar from '../components/TopAppBar/GenericTopBar';
import { Outlet } from 'react-router-dom';

function DefaultAppLayout() {
	return (
		<Box
			sx={{
				display: 'flex-column',
				justifyContent: 'flex-start',
				overflow: 'hidden'
			}}
		>
			<GenericTopBar />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					overflow: 'hidden'
				}}
			>
				<Navbar />
				<Box
					component='main'
					sx={{
						overflow: 'scroll',
						height: '92vh',
						overflowX: 'hidden',
						width: '100%',
						bgcolor: 'lightgray'
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</Box>
	);
}

export default DefaultAppLayout;
