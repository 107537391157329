import axios, { AxiosError, AxiosResponse } from 'axios';

export const AUTH_DISABLED = Boolean(JSON.parse(process.env.REACT_APP_AUTH_DISABLED ?? 'false'));
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const SSO_LOGIN_URL = `${OAUTH_URL}/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${FRONTEND_URL}/login`;

/**
 * Static instance of our api client. It is already set up with the required config.
 */
export const apiClient = axios.create({
	baseURL: API_URL,
	withCredentials: true
});

/**
 * Used when making an api call, it maps the response data & error.
 * @param block Block in which an api call should be executed
 * @returns data of the [AxiosResponse]
 */
export function withApi<T>(block: () => Promise<AxiosResponse<T, unknown>>) {
	return new Promise<T>((resolve, reject) => {
		block()
			.then((response) => {
				resolve(response.data);
			})
			.catch((error: Error | AxiosError) => {
				if (axios.isAxiosError(error)) {
					reject(error.response?.data);
				} else {
					reject(error);
				}
			});
	});
}
